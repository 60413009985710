import { Link } from "@remix-run/react";
import { clsx } from "clsx";

import { SpriteName, Svg } from "./svg";

export const socialMediaProfiles: Array<{
  title: string;
  href: string;
  icon: SpriteName;
}> = [
  {
    title: "Facebook",
    href: "https://facebook.com/bmcreationsdev",
    icon: "facebook",
  },
  {
    title: "Instagram",
    href: "https://instagram.com/bmcreationsdev",
    icon: "instagram",
  },
  {
    title: "GitHub",
    href: "https://github.com/bmcreations",
    icon: "github",
  },
];

export function SocialMedia({
  className,
  invert = false,
}: {
  className?: string;
  invert?: boolean;
}) {
  return (
    <ul
      className={clsx(
        "flex gap-x-10",
        invert ? "text-white" : "text-neutral-950",
        className,
      )}
    >
      {socialMediaProfiles.map((socialMediaProfile) => (
        <li key={socialMediaProfile.title}>
          <Link
            to={socialMediaProfile.href}
            aria-label={socialMediaProfile.title}
            className={clsx(
              "transition",
              invert ? "hover:text-neutral-200" : "hover:text-neutral-700",
            )}
          >
            <Svg
              name={socialMediaProfile.icon}
              className="h-6 w-6 fill-current"
              aria-hidden="true"
            />
          </Link>
        </li>
      ))}
    </ul>
  );
}
